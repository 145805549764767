import {goto} from '$app/navigation';
import {getUserData} from '$lib/utils/datadog';
import {getListingAndMessage} from '$lib/utils/errors';
import {
	getCurrentRouteName,
	getRoutePath
} from '$lib/utils/navigation/navigation';
import {parseWlkLink} from '$lib/utils/wellKnown';
import {siteConfigStore} from '$stores/siteConfig';
import {AuthenticationException} from '@blg/api-exception';
import {datadogLogs} from '@datadog/browser-logs';
import {get} from 'svelte/store';

/** @type {import('@sveltejs/kit').HandleServerError} */
export async function handleError({error, event, status, message}) {
	if (import.meta.env.MODE === 'production') {
		const siteConfig = get(siteConfigStore);

		datadogLogs.logger.error(
			message,
			{
				team: 'bdiff',
				status,
				instance: siteConfig.externalId,
				user: getUserData()
			},
			error as Error
		);
	}

	if (error instanceof AuthenticationException) {
		await goto(getRoutePath('login') || '/login');
		return;
	}

	if (error instanceof Error && is404Error(status, error)) {
		const routeName = getCurrentRouteName(event.url.pathname);

		/* Handle short well kwown. Mandatory here, because isn't a valid route */
		if (event.url.pathname.startsWith('/~')) {
			/* Replace /~ with /wk/ and remove the last slash */
			const wkLink = structuredClone(event.url.pathname)
				.replace('/~', '/wk/')
				.slice(0, -1);
			parseWlkLink(wkLink);
		}

		return {
			status: 404,
			message,
			...getListingAndMessage(routeName)
		};
	}

	return {
		message,
		status
	};
}

function is404Error(status: number, error: App.Error) {
	return status === 404 || error.apiResponse?.statusCode === 404;
}
